<template>
  <div class="allGoods">
    <section class="Title" v-if="allProducts && allProducts.length > 0">
      <p class="TitleBorder"></p>
      <p class="TitleName">{{ $t.cStore.allProducts }}</p>
    </section>
    <div class="rowBox">
      <van-row>
        <van-col span="12" v-for="(item, index) in allProducts" :key="index">
          <div class="productBox">
            <div class="product">
              <figure
                class="productImg"
                @click="getProductDetails(item.ProductNumber, item.ShopId)"
              >
                <van-image
                  class="productIcon"
                  :src="imgUrlFilter(item.ProductPhoto)"
                >
                  <template v-slot:error>
                    <img class="ImgIcon" src="~assets/img/other/empty.gif" />
                  </template>
                </van-image>
              </figure>
              <div
                class="productTitle"
                @click="getProductDetails(item.ProductNumber)"
              >
                <div class="discount" v-if="item.IsCouponProduct">
                  {{ $t.discount }}
                </div>
                <div class="brand" v-if="item.IsBrandProduct">
                  {{ $t.brand }}
                </div>
                <div class="attestation" v-if="item.IsAttestationProduct">
                  {{ $t.attestation }}
                </div>
                <div class="name">{{ item.ProductName }}</div>
              </div>
              <section
                class="info"
                @click="getProductDetails(item.ProductNumber)"
              >
                <p class="unit">$</p>
                <p class="num">{{ item.CurrentPrice }}</p>
                <p class="sale">{{ item.Sales }} {{ $t.cStore.sold }}</p>
              </section>
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "AllGoods",
  props: ["allProducts"],
  data() {
    return {};
  },
  methods: {
    imgUrlFilter(value) {
      // console.log(value.split("|")[0]);
      return this.$api.formatImageUrl(value.split("|")[0]);
    },
    // 跳转详情页
    getProductDetails(ProductNumber, ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "details",
        name: "details",
        query: [
          {
            name: "ProductNumber",
            value: ProductNumber,
          },
          {
            name: "ShopId",
            value: ShopId,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin publicProduct {
  width: 96%;
  border-radius: 10px;
  box-shadow: 0 0 0px 1px #ece7e7;
  background-color: #ffffff;
  margin-right: 4%;
}
@mixin productInfo {
  padding: 0;
  margin: 0;
  text-align: center;
  @include spanNowrap;
}
.allGoods {
  width: 100%;
  .Title {
    @include publicFlex;
    .TitleBorder {
      margin: 0;
      padding: 0;
      height: 5px;
      width: 5px;
      margin-right: 5px;
      border-radius: 10px;
      background-color: #43cd9a;
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
    }
    .TitleName {
      margin: 0;
      padding: 0;
      font-size: 15px;
      color: #000000;
    }
  }
  .rowBox {
    width: 100%;
    margin: 0 auto;
    ::v-deep .van-row {
      .van-col {
        margin-top: 15px;
        &:nth-child(even) {
          .productBox {
            .product {
              // background-color: red;
              @include publicProduct;
              margin-left: 4%;
            }
          }
        }
        &:nth-child(odd) {
          .productBox {
            .product {
              // background-color: red;
              @include publicProduct;
              margin-right: 4%;
            }
          }
        }
      }
    }
    .productBox {
      width: 100%;
      position: relative;
      // background-color: #ffffff;
      background-color: #f6f6f6;
      .productImg {
        margin: 0;
        padding: 0;
        overflow: hidden;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .productIcon {
          width: 100%;
          height: 44.4vw;
          object-fit: cover;
          -webkit-object-fit: cover;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          .ImgIcon {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
          ::v-deep .van-image__img {
            width: 100%;
            height: 100%;
            position: relative;
            object-fit: cover;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
          ::v-deep .van-image__error {
            width: 100%;
            height: 100%;
            position: relative;
            object-fit: cover;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
          }
        }
      }
      .productTitle {
        margin: 8px auto;
        padding: 0;
        width: 90%;
        font-size: 12px;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 45px;
        .discount {
          vertical-align: middle;
          display: inline-block;
          background: red;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 10px;
          margin-right: 5px;
        }
        .brand {
          vertical-align: middle;
          display: inline-block;
          background: #e6a23c;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 10px;
          margin-right: 5px;
        }
        .attestation {
          vertical-align: middle;
          display: inline-block;
          background: #22b196;
          color: white;
          padding: 0px 2px;
          border-radius: 2px;
          font-size: 10px;
          margin-right: 5px;
        }
        .name {
          vertical-align: middle;
          display: inline-block;
        }
      }
      .info {
        width: 90%;
        margin: 0 auto;
        display: flex;
        line-height: 30px;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        padding-bottom: 10px;
        .unit {
          @include productInfo;
          font-size: 14px;
          color: #ff5852;
          width: 8%;
          font-weight: bold;
        }
        .num {
          @include productInfo;
          color: #ff5852;
          font-weight: bold;
          font-size: 16px;
          width: 42%;
          text-align: left;
        }
        .sale {
          @include productInfo;
          color: #a7a7a7;
          text-align: center;
          font-size: 14px;
          width: 50%;
        }
      }
    }
  }
}
</style>
